export enum AppRoutes {
  home = '/',
  about = '/about',
  contact = '/contact',
  terms = '/terms',
  privacy = '/privacy',
  team = '/team',
  blog = '/blog',
  privacypolicy = '/privacypolicy',
  corporate = '/corporate-bookings',
  nri = '/nri',
  bhandarakarwao = '/bhandara-karwao',
  volunteerRegistration = '/volunteer-registration',
}

export const getRouteTitle = (route: AppRoutes): string => {
  switch (route) {
    case AppRoutes.home:
      return 'Home';
    case AppRoutes.about:
      return 'About Us';
    case AppRoutes.contact:
      return 'Contact Us';
    case AppRoutes.terms:
      return 'Terms & Conditions';
    case AppRoutes.privacy:
      return 'Privacy Policy';
    case AppRoutes.team:
      return 'Our Team';
    default:
      return 'Bhandara';
  }
};

import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./MobileAdd.module.scss";
import OptimizedImage from '../common/OptimizedImage';

const NON_ADD_PAGES = ["/bhandarakarwao", "/volunteer-registration"];

const MobileAdd: React.FC = () => {
  const location = useLocation();

  if (NON_ADD_PAGES.includes(location.pathname)) {
    return null;
  }
  return (
    <section className={styles.mobileAdd}>
      <Container fluid className={styles.container}>
        <Row className={styles.contentRow}>
          <Col md={6} className={styles.textContent}>
            <div className={styles.titleWrapper}>
              <h1>Download Bhandara app</h1>
              <p>
                India&apos;s 1st free-food location and spritual food app & Bhandara location
                sharing social media app.
              </p>
            </div>

            <div className={styles.downloadSection}>
              <a
                href="https://play.google.com/store/apps/details?id=com.bhandara.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <OptimizedImage
                  src="/images/playstore.png"
                  alt="Download from Play Store"
                  width={200}
                  height={200}
                  loading="lazy"
                  className={styles.storeIcon}
                />
              </a>
            </div>
          </Col>

          <Col md={6} className={styles.imageContent}>
            <OptimizedImage
              src="/images/downloadapp.png"
              alt="Download our app"
              width={200}
              height={200}
              loading="lazy"
              className={styles.appImage}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MobileAdd;

import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AppRoutes } from './routes/routes';
import { clarity } from './utils/clarity';
import LoadingSpinner from '@components/LoadingSpinner';
import ScrollToTop from '@components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './App.module.scss';
import './App.css';

// Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import MobileAdd from './components/MobileAdd';

// Pages
const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Blog = React.lazy(() => import('./pages/Blog'));
const PolicyPage = React.lazy(() => import('./pages/PrivacyPolicy'));
const Corporate = React.lazy(() => import('./pages/CorporateBookings'));
const Nri = React.lazy(() => import('./pages/Nri'));
const BhandaraKarwaoWrapper = React.lazy(() => import('./pages/BhandaraKarwao'));
const VolunteerRegistration = React.lazy(() => import('./pages/Volunteer'));

const App: React.FC = () => {
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    if (process.env.NODE_ENV === 'production') {
      clarity.init('oaog8fxm67');
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <HelmetProvider>
        <ScrollToTop />
        <div className="App">
          {showButton && (
            <button id="button" className="show" onClick={scrollToTop}></button>
          )}

          <Navbar />
          <div className={styles.mainContent}>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path={AppRoutes.home} element={<Home />} />
                <Route path={AppRoutes.about} element={<About />} />
                <Route path={AppRoutes.contact} element={<Contact />} />
                <Route path={AppRoutes.blog} element={<Blog />} />
                <Route path={AppRoutes.privacypolicy} element={<PolicyPage />} />
                <Route path={AppRoutes.corporate} element={<Corporate />} />
                <Route path={AppRoutes.nri} element={<Nri />} />
                <Route path={AppRoutes.bhandarakarwao} element={<BhandaraKarwaoWrapper />} />
                <Route path={AppRoutes.volunteerRegistration} element={<VolunteerRegistration />} />

                {/* Catch-all route for 404 */}
                <Route path="*" element={
                  <div className="container text-center py-5">
                    <h1>404 - Page Not Found</h1>
                    <p>The page you are looking for does not exist.</p>
                  </div>
                } />
              </Routes>
            </Suspense>
          </div>

          <MobileAdd />
          <Footer />
        </div>
      </HelmetProvider>
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../routes/routes';
import styles from './Navbar.module.scss';
import OptimizedImage from '../common/OptimizedImage';

interface NavItem {
  to: string;
  label: string;
  children?: NavItem[];
}

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const navItems: NavItem[] = [
    { to: AppRoutes.home, label: t('nav.home') },
    { to: AppRoutes.about, label: t('nav.about') },
    {
      to: '#',
      label: t('nav.services'),
      children: [
        { to: AppRoutes.bhandarakarwao, label: t('nav.bhandaraKarwao') },
        { to: AppRoutes.corporate, label: t('nav.corporateBookings') },
        { to: AppRoutes.nri, label: t('nav.nri') },
      ],
    },
    { to: AppRoutes.contact, label: t('nav.contact') },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (activeDropdown !== null && !(event.target as Element).closest(`.${styles.dropdown}`)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [activeDropdown]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (index: number) => (event: React.MouseEvent) => {
    event.preventDefault();
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const isActiveLink = (path: string): boolean => {
    if (path === '#') return false;
    return location.pathname === path;
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ''}`}>
      <div className={styles.container}>
        <Link className={styles.logo} to={AppRoutes.home}>
          <div className={styles.logo}>
            <OptimizedImage 
              src="/images/blogo.png" 
              alt="Bhandara Logo" 
              width={150}
              height={50}
              loading="eager"
              className={styles.logoImage}
            />
          </div>
        </Link>

        <button
          className={styles.toggler}
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarNav"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className={styles.togglerIcon}></span>
        </button>

        <div className={`${styles.collapse} ${isOpen ? styles.show : ''}`}>
          <ul className={styles.nav}>
            {navItems.map((item, index) => (
              <li key={index} className={`${styles.navItem} ${item.children ? styles.dropdown : ''}`}>
                {item.children ? (
                  <>
                    <a
                      href="#"
                      className={`${styles.navLink} ${activeDropdown === index ? styles.active : ''}`}
                      onClick={toggleDropdown(index)}
                      aria-expanded={activeDropdown === index}
                    >
                      {item.label}
                    </a>
                    <ul className={`${styles.dropdownMenu} ${activeDropdown === index ? styles.show : ''}`}>
                      {item.children.map((child, childIndex) => (
                        <li key={childIndex}>
                          <Link
                            className={`${styles.dropdownItem} ${isActiveLink(child.to) ? styles.active : ''}`}
                            to={child.to}
                            onClick={() => setActiveDropdown(null)}
                          >
                            {child.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <Link
                    className={`${styles.navLink} ${isActiveLink(item.to) ? styles.active : ''}`}
                    to={item.to}
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t('nav.language')}
              </a>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => changeLanguage('en')}
                  >
                    English
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => changeLanguage('hi')}
                  >
                    हिंदी
                  </button>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState } from 'react';

interface OptimizedImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  className?: string;
  width?: number;
  height?: number;
  loading?: 'lazy' | 'eager';
  decoding?: 'async' | 'sync' | 'auto';
}

export const OptimizedImage: React.FC<OptimizedImageProps> = ({
  src,
  alt,
  className,
  loading = 'lazy',
  decoding = 'async',
  ...props
}) => {
  const [useWebP, setUseWebP] = useState(true);

  if (!src) return null;

  if (src.includes('http')) {
    return (
      <img
        src={src}
        alt={alt}
        className={className}
        loading={loading}
        decoding={decoding}
        {...props}
      />
    );
  }

  // Convert the original image path to WebP
  const webpSrc = src.replace(/\.(jpg|jpeg|png|gif)$/i, '.webp');

  return (
    <picture>
      {useWebP && (
        <source
          srcSet={webpSrc}
          type="image/webp"
          onError={() => setUseWebP(false)}
        />
      )}
      <img
        src={src}
        alt={alt}
        className={className}
        loading={loading}
        decoding={decoding}
        {...props}
      />
    </picture>
  );
};

export default OptimizedImage;

import React from 'react';
import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {
  isLoading?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ 
  isLoading = true,
  size = 'md'
}) => {
  if (!isLoading) return null;

  return (
    <div className={`${styles.loadingSpinner} ${styles[`size-${size}`]}`}>
      <div 
        className={styles.spinnerBorder} 
        role="status"
        aria-label="Loading..."
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingSpinner;
